<template>
  <div class="pb-2">
    <div class="row">
      <div class="col-12 col-md-6">
        <div class="input-group">
          <input class="form-control"
                 id="search-term"
                 type="text"
                 placeholder="Search"
                 autocomplete="off"
                 v-model="filterText"
                 @keyup.enter="doFilter" />
          
          <div class="input-group-append">
            <button class="btn btn-primary"
                    type="submit"
                    @click="doFilter">
              <i class="fas fa-search"></i>
            </button>
          </div>
          
          <button class="btn btn-outline-primary ml-2"
                  @click="resetFilter">
            <i class="fas fa-sync-alt"></i>
          </button>

          <button class="btn btn-outline-success ml-2"
                  type="button"
                  @click="createEnterprise">
            <i class="fas fa-plus"></i> Create Account
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  const CreateAccount = () => import('./CreateEnterprise.vue');

  export default {
    data() {
      return {
        filterText : '',
      }
    },
    methods : {

      /**
       * Filter
       */
      doFilter() {
        this.$events.fire('filter-set', this.filterText);
      },

      /**
       * Reset Filter
       */
      resetFilter() {
        this.filterText = '';  // clear the text in text input
        this.$events.fire('filter-reset');
      },

      /**
       * Open Create Enterprise Account modal
       */
      createEnterprise() {
        this.$modal.show(CreateAccount, {}, {
          height   : 'auto',
          adaptive : true,
        });
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../../assets/scss/components/admin/enterprise/filter-bar";
</style>